import {useSelector} from 'react-redux';
import {getUserLengthMeasurement} from './unitSelectors';

export const useCalculateLengthFrontend = () => {
  const measurement = useSelector(getUserLengthMeasurement);
  let useImperial = false;
  if (measurement === 'imperial') {
    useImperial = true;
  }
  return (value: number | string, dezimal: 1 | 10 | 100 | 1000 = 100) => {
    let newValue = value;
    if (typeof newValue === 'string') {
      newValue = newValue.replace(',', '.');
      if (newValue === '' || isNaN(newValue as any)) {
        return value;
      }
      newValue = parseFloat(newValue);
    }
    if (useImperial) {
      newValue = newValue / 2.54;
    }
    return Math.round(newValue * dezimal) / dezimal;
  };
};
