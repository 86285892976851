import {useEffect, useState} from 'react';
import {useFirestore} from 'react-redux-firebase';
import {UserType} from '../types/User';

export const useGetActiveAthletes = (userId: string) => {
  const firestore = useFirestore();

  const [data, setData] = useState<UserType[]>();
  useEffect(() => {
    const unsubscribe = firestore
      .collection('user')
      .doc(userId)
      .collection('athleteStates')
      .where('currentState', '==', 'active')
      .onSnapshot(async (querySnapshot) => {
        const tmpData = [];
        for (const stateDoc of querySnapshot.docs) {
          firestore
            .get({
              collection: 'user',
              doc: stateDoc.id,
            })
            .then((athleteDoc) => {
              const athleteData = athleteDoc.data();
              tmpData.push(athleteData);
              // Last document loaded
              if (tmpData.length === querySnapshot.docs.length)
                setData(tmpData);
            });
        }
      });
    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return data;
};
