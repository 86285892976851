import {WorkoutKeyboardTypes} from './WorkoutKeyboard';
export type UserType = {
  documentVersion: number;
  accountDisabled: boolean;
  id: string;
  type: 'Athlete' | 'Trainer' | 'Trainer & Athlete';
  permissions: PermissionType;
  training: ProfileTrainingType;
  app: ProfileAppType;
  webApp: ProfileWebAppType;
  email: string;
  countryCode: string;
  previousMail: string;
  firstName: string;
  lastName: string;
  birthDate: string;
  sport: string;
  timezone: string;
  measurement: string;
  lengthMeasurement: 'metric' | 'imperial';
  volumeMeasurement: 'metric' | 'imperial';
  username: string;
  gender: string;
  dateRegistered: string;
  currentCycle: number;
  totalCycles: number;
  currentCycleStartDate: string;
  planningStartDate?: string;
  currentWeek: number;
  startDayInWeek: number;
  contact: ContactType;
  athlete: {
    lastLogin: string;
    competitionDate: string;
    competitionName: string;
    trainerSettings: TrainerAthleteSettingsType;
    profilePicture: string;
    oneRMs: oneRMsType;
    trainerInfo: TrainerInfoType;
    training: {
      lastDailyCheckId: string;
      lastWeeklyCheckId: string;
      currentCycle: number;
      totalCycles: number;
      planningStartDate: Array<{startDay: string; index: number}>;
      currentWeek: number;
      oneRMs: oneRMsType;
    };
    registrationFlags: {
      personal: boolean;
      morePersonal: boolean;
      trainerAVV: boolean;
      registrationCompleted: boolean;
      accountUnlocked: boolean;
      currentCycleSetByTrainer: boolean;
      emailVerified: boolean;
      profilePictureUploaded: boolean;
      trainerCode: boolean;
      metrics: boolean;
      notifications: boolean;
    };
    consent: {
      privacyPolicy: Consent;
      trainerAVV: Consent & {
        text: string;
        trainerId: string;
      };
    };
    newFeatureInfo: {
      dateConfirmed: string;
      versionConfirmed: string;
    };
    appSettings: {
      automaticBreak: boolean;
      workoutKeyboard: WorkoutKeyboardTypes;
      breakDuration: number;
      appLang: string;
      caloriesCalculation: boolean;
      dateFormat: 'normal' | 'us';
      useDarkMode: boolean;
    };
  };
  trainer: {
    enablePayment: boolean;
    paymentFlags: {
      subscriptionSetupCompleted: boolean;
      customer: {
        created: boolean;
        subscription: {
          created: boolean;
          cancelled_at_period_end: boolean;
          cancel_at: string;
          current_period_end: string;
        };
      };
      checkout: {
        session: {
          completed: boolean;
        };
      };
      invoice: {
        payment_succeeded: boolean;
        paid: boolean;
      };
      payment_intent: {
        type: string;
        status:
          | 'requires_payment_method'
          | 'requires_confirmation'
          | 'requires_action'
          | 'processing'
          | 'requires_capture'
          | 'canceled'
          | 'succeeded';
      };
    };
    totalAthletes: string; // Wird vom Trainer selbst angegeben -> hat keine feste Aussage
    totalPending: number;
    totalActive: number;
    totalInactive: number;
    lastLogin: string;
    profilePicture: string;
    code: string;
    businessName: string;
    athleteIds: string[];
    athleteStates: AthleteStateType;
    athleteTags: AthleteTagType[];
    dayTemplatesArray: Array<TemplateArrayType>;
    weekTemplatesArray: Array<TemplateArrayType>;
    cycleTemplatesArray: Array<TemplateArrayType>;
    registrationFlags: {
      consent: boolean;
      sport: boolean;
      onboardingOverlayShown: boolean;
      trainerCode: boolean;
      personal: boolean;
      registrationCompleted: boolean;
      accountUnlocked: boolean;
      exerciseLibraryCreated: boolean;
    };
    consent: {
      commercialUser: Consent;
      newsletter: Consent;
      privacyPolicy: Consent;
      termsAndConditions: Consent;
    };
    trainerAVV: {
      changeDate: string;
      text: string;
    };
    webSettings: {
      webLang: string;
      dontWarnSaveSet: boolean;
      dontShowEditAthleteValuesWarning: boolean;
    };
    settings: TrainerSettings;
    stripe: {
      stripeId: string;
      stripeLink: string;
      email: string;
      livemode: boolean;
      currentSubscriptionId: string;
      currentSubscriptionItemId: string;
    };
  };
};

type Consent = {
  changeDate: string;
  accepted: boolean;
};

export type TemplateArrayType = {
  name: string;
  id: string;
};

export type ContactType = {
  street: string;
  streetNumber: string;
  zipCode: string;
  city: string;
  country: string;
  countryCode: string;
  phoneNumber: string;
};

type TrainerInfoType = {
  trainerId: string;
  trainerCode: string;
  currentState: AthleteActiveState;
  trainerHistory: TrainerHistoryType[];
};

export type AthleteStateType = {
  [athleteId: string]: {
    tags: AthleteTagType[];
    currentState: AthleteActiveState;
    stateChanges: Array<AthleteStateChangeType>;
  };
};
export type AthleteStateChangeType = {
  oldState: AthleteActiveState;
  newState: AthleteActiveState;
  date: string;
  index: number;
};

export type AthleteTagType = {
  title: string;
  date: string;
  style: any; // CSSProperties in WebApp, ViewStyle in App
};

type TrainerHistoryType = {trainerId: string; date: string; index: number};

export type AthleteActiveState =
  | 'active'
  | 'inactive'
  | 'noTrainer'
  | 'pending'
  | 'deleted'
  | 'changedTrainer';

type PermissionType = {
  ads: boolean;
  agb: boolean;
};

type ProfileTrainingType = {
  sport: string;
  unit: string;
};

type ProfileAppType = {
  lang: string;
  os: string;
  browser: string;
  countryCode: string;
  dateRegistered: string;
  timezone: string;
  lastLogin: string;
  appVersion: string;
};

type ProfileWebAppType = {
  lastUpdateDate: string;
};

export type oneRMsType = {
  oneRM_bench: number;
  oneRM_squat: number;
  oneRM_deadlift: number;
  oneRM_muscleUp: number;
  oneRM_dip: number;
  oneRM_pullUp: number;
  cycles: {[name: string]: oneRMsType};
};

export type TrainerSettings = {
  notificationOptions: TrainerNotificationOptionsType;
  athleteVideoTabSettings: boolean;
  webLang: string;
  dateFormat: string;
};
export type TrainerAthleteSettingsType = {
  trainerNote: string;
  trainerCheckOptions: TrainerCheckOptionsType;
  trainerOneRMTable: trainerOneRMTableOptions;
  showTrainerOneRMCycleTable: boolean;
  percentageRoundTarget: number;
  notificationOptions: TrainerNotificationOptionsType;
  useRir: boolean;
};

export type TrainerCheckOptionsType = {
  dailyCheck: boolean;
  weeklyCheck: boolean;
};

export type TrainerNotificationOptionsType = {
  weeklyMail: boolean;
};

export enum trainerOneRMTableOptions {
  calisthenics = 'Calisthenics',
  powerlifting = 'Powerlifting',
  none = 'None',
}
