import {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {getUserWeightMeasurement} from './unitSelectors';

export const useCalculateLoadFrontend = () => {
  const measurement = useSelector(getUserWeightMeasurement);
  let useLBS = false;
  if (measurement === 'lbs') {
    useLBS = true;
  }
  return useCallback(
    (
      value: number | string | undefined,
      dezimal: 1 | 10 | 100 | 1000 = 100,
    ) => {
      let newValue = value;
      if (newValue === '' || newValue === undefined) {
        return value;
      }
      if (typeof newValue === 'string') {
        newValue = newValue.replace(',', '.');
        if (isNaN(newValue as any)) {
          return value;
        }
        newValue = parseFloat(newValue);
        if (isNaN(newValue as any)) {
          return value;
        }
      }
      if (useLBS) {
        newValue = newValue * 2.2046;
      }
      return Math.round(newValue * dezimal) / dezimal;
    },
    [useLBS],
  );
};
