import {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {getUserWeightMeasurement} from './unitSelectors';

export const useCalculateLoadBackend = () => {
  const measurement = useSelector(getUserWeightMeasurement);
  let useLBS = false;
  if (measurement === 'lbs') {
    useLBS = true;
  }
  return useCallback(
    (value: number | string) => {
      let newValue = value;
      if (newValue === '' || newValue === undefined) {
        return value;
      }
      if (typeof newValue === 'string') {
        newValue = newValue.replace(',', '.');
        if (isNaN(newValue as any)) {
          return value;
        }
        newValue = parseFloat(newValue);
        if (isNaN(newValue as any)) {
          return value;
        }
      }
      if (useLBS) {
        return newValue / 2.2046;
      }
      return newValue;
    },
    [useLBS],
  );
};
