import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {AthletesCardSection} from '../components/AthletesCardSection';
import {FilterSelector} from '../components/FilterSelector';
import {
  resetAthleteTagFilter,
  setCurrentAthleteId,
} from '../store/athleteSlice';

export const AthletesScreen = () => {
  const dispatch = useDispatch();
  const [filterText, setFilterText] = useState('');

  Intercom('update', {
    vertical_padding: 20,
  });

  useEffect(() => {
    dispatch(resetAthleteTagFilter());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(setCurrentAthleteId(undefined));
  }, []);

  return (
    <>
      <FilterSelector filterText={filterText} setFilterText={setFilterText} />

      <AthletesCardSection
        filterText={filterText}
        setFilterText={setFilterText}
      />
    </>
  );
};
