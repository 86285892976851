import {DailyProgressImageType} from './UserStorageData';

export type TrainingType = {
  version?: number;
  startDate: string;
  activeTrainingStartDate: string;
  calendarWeek: number; //must be 6 digit long exp. 202106, YYYYWW
  trainingWeek: number;
  trainingCycle: number;
  id: string;
  trainingDays: DayType[];
  defineDailyMakros: boolean;
  feedback: Feedback | string;
  athleteFeedback: string;
  weeklyCheck: CheckType[];
  createdAt: string;
  changedAt: string;
  days: string[];
  dailyIdArray?: string[];
  name: string;
  weeklyCheckId: string;
};

export type WeekType = {
  day0: DayType;
  day1: DayType;
  day2: DayType;
  day3: DayType;
  day4: DayType;
  day5: DayType;
  day6: DayType;
};

export type DayIndices =
  | 'day0'
  | 'day1'
  | 'day2'
  | 'day3'
  | 'day4'
  | 'day5'
  | 'day6';

export const dayIndicesArray = [
  'day0',
  'day1',
  'day2',
  'day3',
  'day4',
  'day5',
  'day6',
] as DayIndices[];

export type DayType = {
  overlappingDay?: boolean; // Is true if overlapping with last weeks training
  name?: string;
  noTraining: boolean;
  date: string;
  warmUp: ExerciseType[];
  exercises: ExercisesContainer;
  coolDown: ExerciseType[];
  dailyCheck: CheckType[];
  dailyAthleteRemark?: string;
  dailyProgressImages?: DailyProgressImageType;
  startTime: string;
  endTime: string;
  index: number;
  sessionRpe: number;
  sessionNote: string;
  supersetMeta: SupersetMeta;
};

export type ExercisesContainer = {[exerciseId: string]: ExerciseType};

export type ExerciseType = {
  id: string;
  category: string;
  name: string;
  equipment: string;
  sets: SetsContainerType;
  break: number;
  startTime: string;
  endTime: string;
  index: number;
  trainerNote?: string;
  athleteNote?: string;
  usePercentageCalc?: boolean;
  primaryExercise?: string;
  useRir?: boolean;
  supersetId?: string;
};

export type SupersetMeta = {
  [id: string]: {
    id: string;
    name: string;
    exerciseIndices: number[];
  };
};

export type SetsContainerType = {
  [key: string]: SetSplitterType;
};

export type SetSplitterType = {
  trainer: SetTypeTrainer;
  athlete: SetType;
  index: number;
};

export type SetType = {
  isDone: boolean;
  note: string;
  reps: number;
  rpe: number;
  load: number;
  video: string;
  duration: number;
  breaks: number;
  oneRm: number;
  percentOneRm?: number;
};

export type SetTypeTrainer = {
  note: string;
  reps: string;
  rpe: string;
  load: string;
  video: string;
  videoRequired: boolean;
  duration: number;
  breaks: number;
  oneRm: number;
  percentOneRm: string;
  usePercentOneRm: boolean;
  trainerPercentage: number;
};

export type Feedback = {
  video: string[];
  note: string[];
  image: string[];
};

export type CheckType = {
  category: CategoryTypes;
  question: QuestionSplitterType[];
  index: number;
};

export type QuestionSplitterType = {
  trainer: QuestionType;
  athlete: QuestionType;
  index: number;
};

export type QuestionType = {
  id: string;
  title: string;
  type: QuestionTypes;
  value: number | string;
  options: string[];
};

export enum QuestionTypes {
  input,
  survey,
  written,
}

export enum CategoryTypes {
  nutrition = 'Nutrition',
  hydration = 'Hydration',
  body = 'Body',
  activity = 'Activity',
  sleep = 'Sleep',
  regeneration = 'Regeneration',
  training = 'Training',
  general = 'General',
}
