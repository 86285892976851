import firebase from 'firebase/app';
import {Dispatch} from 'react';
import {ExtendedFirestoreInstance} from 'react-redux-firebase';
import {
  addDailyCheckQuery,
  addWeeklyCheckQuery,
  checkCategoryLibraryQuery,
  specificDailyCheckTemplateQuery,
  specificWeeklyCheckTemplateQuery,
  usersDataPublicProfileQuery,
  webSettingsLayoutQuery,
} from '../logic/firestore';
import {
  setSelectedDailyCheckTemplateId,
  setSelectedWeeklyCheckTemplateId,
} from '../store/checkEditorSlice';
import {standardCheckCategoryLibrary} from '../traindoo_shared/types/Check';
import {standardDailyCheckTemplate} from '../types/DefaultDailyCheck';
import {standardWeeklyCheckTemplate} from '../types/DefaultWeeklyCheck';
import {defaultDashboardLayout} from '../utils/DefaultDashboardLayout';

export const generateStandardChecks = async (
  firestore: ExtendedFirestoreInstance,
  userId: string,
  dispatch: Dispatch<any>,
) => {
  const dailyDoc = await firestore.add(
    addDailyCheckQuery(userId),
    standardDailyCheckTemplate,
  );
  firestore.set(
    specificDailyCheckTemplateQuery(userId, dailyDoc.id),
    {id: dailyDoc.id},
    {merge: true},
  );
  const weeklyDoc = await firestore.add(
    addWeeklyCheckQuery(userId),
    standardWeeklyCheckTemplate,
  );
  firestore.set(
    specificWeeklyCheckTemplateQuery(userId, weeklyDoc.id),
    {id: weeklyDoc.id},
    {merge: true},
  );
  firestore.set(
    webSettingsLayoutQuery(userId),
    {dailyCheckLayout: defaultDashboardLayout()},
    {merge: true},
  );
  firestore.set(
    usersDataPublicProfileQuery(userId),
    {
      trainer: {
        dailyCheckInfo: {
          dailyCheckArray: firebase.firestore.FieldValue.arrayUnion({
            id: dailyDoc.id,
            name: standardDailyCheckTemplate.name,
          }),
          standardDailyCheckId: dailyDoc.id,
        },
        weeklyCheckInfo: {
          weeklyCheckArray: firebase.firestore.FieldValue.arrayUnion({
            id: weeklyDoc.id,
            name: standardWeeklyCheckTemplate.name,
          }),
          standardWeeklyCheckId: weeklyDoc.id,
        },
      },
    },
    {merge: true},
  );
  firestore.set(
    checkCategoryLibraryQuery(userId),
    standardCheckCategoryLibrary,
    {
      merge: true,
    },
  );
  dispatch(setSelectedDailyCheckTemplateId(dailyDoc.id));
  dispatch(setSelectedWeeklyCheckTemplateId(weeklyDoc.id));
};
