export type ExerciseLibraryType = {
  [exerciseName: string]: ExerciseLibraryEntryType;
};

export type ExerciseLibraryEntryType = {
  name: string;
  category: 'LIBRARY_PRIMARY_EXERCISE' | 'LIBRARY_SECONDARY_EXERCISE';
  linkedPrimaryEx?: string;
  variationOf?: string;
  description: string;
  muscleGroup: MuscleType[];
  videoLinks: Array<WebMediaContent>;
  photoLinks: Array<WebMediaContent>;
  equipmentDesc: string;
  archived?: boolean;
};

export type MuscleType =
  | 'MUSCLE_GROUP_CHEST'
  | 'MUSCLE_GROUP_BACK'
  | 'MUSCLE_GROUP_TRICEPS'
  | 'MUSCLE_GROUP_SHOULDER'
  | 'MUSCLE_GROUP_BICEPS'
  | 'MUSCLE_GROUP_QUADS'
  | 'MUSCLE_GROUP_HAMS'
  | 'MUSCLE_GROUP_GLUTEN'
  | 'MUSCLE_GROUP_ABS'
  | 'MUSCLE_GROUP_NECK'
  | 'MUSCLE_GROUP_FOREARM'
  | 'MUSCLE_GROUP_CALVES'
  | 'MUSCLE_GROUP_ADDUCTORS'
  | 'MUSCLE_GROUP_ABDUCTORS';

export const muscleTypeArray: MuscleType[] = [
  'MUSCLE_GROUP_CHEST',
  'MUSCLE_GROUP_BACK',
  'MUSCLE_GROUP_TRICEPS',
  'MUSCLE_GROUP_SHOULDER',
  'MUSCLE_GROUP_BICEPS',
  'MUSCLE_GROUP_QUADS',
  'MUSCLE_GROUP_HAMS',
  'MUSCLE_GROUP_GLUTEN',
  'MUSCLE_GROUP_ABS',
  'MUSCLE_GROUP_NECK',
  'MUSCLE_GROUP_FOREARM',
  'MUSCLE_GROUP_CALVES',
  'MUSCLE_GROUP_ADDUCTORS',
  'MUSCLE_GROUP_ABDUCTORS',
];

export type WebMediaContent = {
  link: string;
  name: string;
};
