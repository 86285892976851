import {ReduxFirestoreQuerySetting} from 'react-redux-firebase';

export const mostRecentChatMsgQuery = (chatId: string) => {
  return {
    collection: 'messages',
    where: ['chatId', '==', chatId],
    orderBy: [['sentAt', 'desc']],
    limit: 1,
    storeAs: 'mostRecentChatMsgQuery',
  } as ReduxFirestoreQuerySetting;
};

export const chatMessageQuery = (
  messageId: string,
): ReduxFirestoreQuerySetting => {
  return {
    collection: 'messages',
    doc: messageId,
  } as ReduxFirestoreQuerySetting;
};

export const allChatsQuery = (userId: string): ReduxFirestoreQuerySetting => {
  return {
    collection: 'chats',
    where: ['participantIds', 'array-contains', userId],
    storeAs: 'chats',
  } as ReduxFirestoreQuerySetting;
};

export const specificChatQuery = (
  chatId: string,
): ReduxFirestoreQuerySetting => {
  return {
    collection: 'chats',
    doc: chatId,
  } as ReduxFirestoreQuerySetting;
};
