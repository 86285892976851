import {Checkbox, FormControlLabel} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {CSSProperties} from 'react';
import {primary_green, white} from '../styles/colors';

type Props = {
  label: string;
  value: boolean;
  onChange: (newValue: boolean) => void;
};

const useStyles = makeStyles(() =>
  createStyles({
    label: {
      color: white,
    },
    select: {
      '&:after': {
        borderColor: primary_green,
      },
      '&:not(.Mui-disabled):hover::before': {
        borderColor: primary_green,
      },
    },
  }),
);

const SimpleCheckbox = (props: Props) => {
  const classes = useStyles();

  const handleChange = (_: any, checked: boolean) => {
    props.onChange(checked);
  };
  return (
    <FormControlLabel
      style={{
        width: '100%',
        justifyContent: 'space-between',
        paddingRight: 20,
        gap: 20,
      }}
      control={
        <Checkbox
          checked={props.value}
          className={classes.select}
          style={styles.select}
          onChange={handleChange}
        />
      }
      label={props.label}
      labelPlacement="start"
      className={classes.label}
    />
  );
};

type Styles = {
  form: CSSProperties;
  select: CSSProperties;
};

const styles: Styles = {
  form: {
    backgroundColor: 'red',
  },
  select: {
    color: white,
    //alignItems: 'flex-end',
    height: 40,
  },
};
export default SimpleCheckbox;
