import {UnitIdType} from '../units/UnitTypes';
import {ImageUrlType} from './UserStorageData';

// ATHLETE DAILY CHECK START
// Here we safe the Trainer and Athlete Values
// Question infos are saved in the Category Library and only referenced here
// ######################
export type CheckAthleteType = {
  name: string;
  id: string;
  templateId: string;
  date: string;
  done?: boolean;
  categories: {
    [categoryName: string]: CheckCategoryAthleteType;
  };
};

export type CheckCategoryAthleteType = {
  id: string;
  index: number;
  defineDaily?: boolean;
  questions: {[questionId: string]: CheckQuestionAthleteType};
};

export type CheckQuestionAthleteType = {
  index: number;
  id: string;
  trainerValue?: string;
  athleteText?: string;
  athleteValue?: number;
  athleteImageLink?: ImageUrlType;
};
// ######################
// ATHLETE DAILY CHECK END

// CATEGORY LIBRARY START
// All Templates and Checks get the question information from the library
// ######################
export type CheckCategoryLibrary = {
  [categoryId: string]: CheckCategoryTemplate;
};

export type CheckCategoryTemplate = {
  name: string;
  id: string;
  notEditable?: boolean;
  notDeletable?: boolean;
  translated?: boolean;
  usedIn?: UsedInType;
  archived?: boolean;
  questions: {[questionId: string]: CheckQuestionTemplate};
};

export type UsedInType = {
  daily: string[];
  weekly: string[];
  generic: string[];
};

export type CheckQuestionTemplate = {
  index?: number;
  id: string;
  archived?: boolean;
  usedIn?: UsedInType;
  name: string;
  question: string;
  notEditable?: boolean; // Overwrites notDeletable: not editable includes not deletable
  notDeletable?: boolean;
  translated?: boolean;
  type: CheckQuestionInputType;
  defaultValue?: number;
  placeholder?: string;
  answers?: string[];
  unit?: UnitIdType;
};

export type CheckQuestionInputType =
  | 'input'
  | 'survey'
  | 'buttonSurvey'
  | 'comment'
  | 'longText'
  | 'media';

export const standardCheckCategoryLibrary: CheckCategoryLibrary = {
  General: {
    name: 'CATEGORY_GENERAL',
    id: 'General',
    notDeletable: true,
    translated: true,
    questions: {
      AthleteRemark: {
        index: 0,
        id: 'AthleteRemark',
        name: 'DAILY_REMARK',
        question: 'DAILY_CHECK_REMARK_TITLE',
        notEditable: true,
        translated: true,
        type: 'longText',
        placeholder: 'DAILY_CHECK_REMARK_PLACEHOLDER',
      },
      WeeklyStress: {
        index: 1,
        id: 'WeeklyStress',
        name: 'WEEKLY_STRESS',
        question: 'WEEKLY_STRESS_QUESTION',
        notEditable: true,
        translated: true,
        type: 'survey',
        answers: [
          'SURVEY_VERY_HIGH',
          'SURVEY_HIGH',
          'SURVEY_OK',
          'SURVEY_LOW',
          'SURVEY_VERY_LOW',
        ],
      },
      WeeklyIntegration: {
        index: 2,
        id: 'WeeklyIntegration',
        name: 'WEEKLY_INTEGRATION',
        question: 'WEEKLY_INTEGRATION_QUESTION',
        notEditable: true,
        translated: true,
        type: 'survey',
        answers: [
          'SURVEY_VERY_BAD',
          'SURVEY_BAD',
          'SURVEY_OK',
          'SURVEY_GOOD',
          'SURVEY_VERY_GOOD',
        ],
      },
      WeeklyProblemsIntegration: {
        index: 3,
        id: 'WeeklyProblemsIntegration',
        name: 'WEEKLY_INTEGRATION_PROBLEMS',
        question: 'WEEKLY_INTEGRATION_PROBLEMS_QUESTION',
        notEditable: true,
        translated: true,
        type: 'comment',
      },
      WeeklyFeedback: {
        index: 4,
        id: 'WeeklyFeedback',
        name: 'WEEKLY_FEEDBACK',
        question: 'WEEKLY_FEEDBACK_QUESTION',
        notEditable: true,
        translated: true,
        type: 'comment',
      },
      WeeklyRemark: {
        index: 5,
        id: 'WeeklyRemark',
        name: 'WEEKLY_REMARK_TITLE',
        question: 'WEEKLY_REMARK_TITLE',
        placeholder: 'WEEKLY_REMARK_PLACEHOLDER',
        notEditable: true,
        translated: true,
        type: 'longText',
      },
    },
  },
  Nutrition: {
    id: 'Nutrition',
    name: 'CATEGORY_NUTRITION',
    notEditable: true,
    translated: true,
    questions: {
      Calories: {
        index: 0,
        name: 'DAILY_CALORIES',
        id: 'Calories',
        question: 'DAILY_CALORIES_QUESTION',
        type: 'input',
        defaultValue: 0,
        unit: 'kcal',
        notEditable: true,
        translated: true,
      },
      Carbohydrates: {
        index: 1,
        name: 'DAILY_CARBOHYDRATES',
        id: 'Carbohydrates',
        question: 'DAILY_CARBOHYDRATES_QUESTION',
        type: 'input',
        defaultValue: 0,
        unit: 'gUniversal',
        notEditable: true,
        translated: true,
      },
      Proteins: {
        index: 2,
        name: 'DAILY_PROTEINS',
        id: 'Proteins',
        question: 'DAILY_PROTEINS_QUESTION',
        type: 'input',
        defaultValue: 0,
        unit: 'gUniversal',
        notEditable: true,
        translated: true,
      },
      Fats: {
        index: 3,
        name: 'DAILY_FATS',
        id: 'Fats',
        question: 'DAILY_FATS_QUESTION',
        type: 'input',
        defaultValue: 0,
        unit: 'gUniversal',
        notEditable: true,
        translated: true,
      },
    },
  },
  WeeklyNutrition: {
    id: 'WeeklyNutrition',
    name: 'CATEGORY_WEEKLY_NUTRITION',
    notDeletable: true,
    translated: true,
    questions: {
      WeeklyHunger: {
        index: 0,
        name: 'WEEKLY_HUNGER',
        id: 'WeeklyHunger',
        question: 'WEEKLY_HUNGER_QUESTION',
        type: 'survey',
        answers: [
          'SURVEY_VERY_STRONG',
          'SURVEY_STRONG',
          'SURVEY_OK',
          'SURVEY_LOW',
          'SURVEY_VERY_LOW',
        ],
        notEditable: true,
        translated: true,
      },
      WeeklyNutrition: {
        index: 1,
        name: 'WEEKLY_NUTRITION_DIFFICULTY',
        id: 'WeeklyNutrition',
        question: 'WEEKLY_NUTRITION_DIFFICULTY_QUESTION',
        type: 'survey',
        answers: [
          'SURVEY_VERY_DIFFICULT',
          'SURVEY_DIFFICULT',
          'SURVEY_OK',
          'SURVEY_EASY',
          'SURVEY_VERY_LITTLE',
        ],
        notEditable: true,
        translated: true,
      },
      WeeklyNutritionQuestion: {
        index: 2,
        name: 'WEEKLY_NUTRITION_QUESTIONS',
        id: 'WeeklyNutritionQuestion',
        question: 'WEEKLY_NUTRITION_QUESTIONS_QUESTION',
        type: 'longText',
        notEditable: true,
        translated: true,
      },
    },
  },
  Body: {
    name: 'CATEGORY_BODY',
    id: 'Body',
    notDeletable: true,
    translated: true,
    questions: {
      Weight: {
        index: 0,
        name: 'DAILY_WEIGHT',
        id: 'Weight',
        question: 'DAILY_WEIGHT_QUESTION',
        type: 'input',
        defaultValue: 0,
        unit: 'kg',
        notEditable: true,
        translated: true,
      },
      WaistSize: {
        index: 1,
        name: 'DAILY_WAIST_SIZE',
        id: 'WaistSize',
        question: 'DAILY_WAIST_SIZE_QUESTION',
        type: 'input',
        defaultValue: 0,
        unit: 'cm',
        notEditable: true,
        translated: true,
      },
    },
  },
  Activity: {
    notDeletable: true,
    name: 'CATEGORY_ACTIVITY',
    id: 'Activity',
    translated: true,

    questions: {
      StepsAmount: {
        index: 0,
        name: 'DAILY_STEPS',
        id: 'StepsAmount',
        question: 'DAILY_STEPS_QUESTION',
        type: 'input',
        defaultValue: 0,
        notEditable: true,
        translated: true,
      },
    },
  },
  Sleep: {
    notDeletable: true,
    name: 'CATEGORY_SLEEP',
    id: 'Sleep',
    translated: true,
    questions: {
      SleepDuration: {
        index: 0,
        name: 'DAILY_SLEEP_DURATION',
        id: 'SleepDuration',
        question: 'DAILY_SLEEP_DURATION_QUESTION',
        type: 'input',
        defaultValue: 0,
        unit: 'h',
        notEditable: true,
        translated: true,
      },
      SleepQuality: {
        index: 1,
        name: 'DAILY_SLEEP_QUALITY',
        id: 'SleepQuality',
        question: 'DAILY_SLEEP_QUALITY_QUESTION',
        type: 'survey',
        answers: [
          'SURVEY_VERY_BAD',
          'SURVEY_BAD',
          'SURVEY_OK',
          'SURVEY_GOOD',
          'SURVEY_VERY_GOOD',
        ],
        notEditable: true,
        translated: true,
      },
      WeeklySleep: {
        index: 2,
        name: 'WEEKLY_SLEEP_AVERAGE',
        id: 'WeeklySleep',
        question: 'WEEKLY_SLEEP_AVERAGE_QUESTION',
        type: 'survey',
        answers: [
          'SURVEY_SLEEP_VERY_HIGH',
          'SURVEY_SLEEP_HIGH',
          'SURVEY_SLEEP_MIDDLE',
          'SURVEY_SLEEP_LOW',
          'SURVEY_SLEEP_VERY_LOW',
        ],
        notEditable: true,
        translated: true,
      },
    },
  },
  Regeneration: {
    notDeletable: true,
    translated: true,
    name: 'CATEGORY_REGENERATION',
    id: 'Regeneration',
    questions: {
      RegenerationScore: {
        index: 0,
        name: 'DAILY_REGENERATION',
        id: 'RegenerationScore',
        question: 'DAILY_REGENERATION_QUESTION',
        type: 'survey',
        answers: [
          'SURVEY_VERY_BAD',
          'SURVEY_BAD',
          'SURVEY_OK',
          'SURVEY_GOOD',
          'SURVEY_VERY_GOOD',
        ],
        notEditable: true,
        translated: true,
      },
      SorenessScore: {
        index: 1,
        name: 'DAILY_SORENESS',
        id: 'SorenessScore',
        question: 'DAILY_SORENESS_QUESTION',
        type: 'survey',
        answers: [
          'SURVEY_SORENESS_VERY_GOOD',
          'SURVEY_SORENESS_GOOD',
          'SURVEY_SORENESS_OK',
          'SURVEY_SORENESS_BAD',
          'SURVEY_SORENESS_VERY_BAD',
        ],
        notEditable: true,
        translated: true,
      },
    },
  },
  Hydration: {
    name: 'CATEGORY_HYDRATION',
    id: 'Hydration',
    notDeletable: true,
    translated: true,
    questions: {
      HydrationAmount: {
        index: 0,
        name: 'DAILY_HYDRATION',
        id: 'HydrationAmount',
        question: 'DAILY_HYDRATION_QUESTION',
        type: 'input',
        defaultValue: 2.5,
        unit: 'l',
        notEditable: true,
        translated: true,
      },
    },
  },
  ProgressImages: {
    name: 'CATEGORY_PROGRESS_IMAGES',
    id: 'ProgressImages',
    translated: true,
    notDeletable: true,
    questions: {
      Front: {
        index: 0,
        name: 'DAILY_PROGRESS_IMAGES_FRONT',
        id: 'Front',
        question: 'DAILY_PROGRESS_IMAGES_FRONT',
        type: 'media',
        notEditable: true,
        translated: true,
      },
      Back: {
        index: 1,
        name: 'DAILY_PROGRESS_IMAGES_BACK',
        id: 'Back',
        question: 'DAILY_PROGRESS_IMAGES_BACK',
        type: 'media',
        notEditable: true,
        translated: true,
      },
      Side: {
        index: 2,
        name: 'DAILY_PROGRESS_IMAGES_SIDE',
        id: 'Side',
        question: 'DAILY_PROGRESS_IMAGES_SIDE',
        type: 'media',
        notEditable: true,
        translated: true,
      },
      Additional: {
        index: 3,
        name: 'DAILY_PROGRESS_IMAGES_ADDITIONAL',
        id: 'Additional',
        question: 'DAILY_PROGRESS_IMAGES_ADDITIONAL',
        type: 'media',
        notEditable: true,
        translated: true,
      },
    },
  },
  Training: {
    name: 'CATEGORY_TRAINING',
    id: 'Training',
    translated: true,
    notDeletable: true,
    questions: {
      WeeklyExhaustion: {
        index: 0,
        name: 'WEEKLY_TIRED',
        id: 'WeeklyExhaustion',
        question: 'WEEKLY_TIRED_QUESTION',
        type: 'survey',
        notEditable: true,
        translated: true,
        answers: [
          'SURVEY_VERY',
          'SURVEY_LITTLE',
          'SURVEY_OK',
          'SURVEY_BIT',
          'SURVEY_NOT',
        ],
      },
      WeeklyPain: {
        index: 1,
        name: 'WEEKLY_SORENESS',
        id: 'WeeklyPain',
        question: 'WEEKLY_SORENESS_QUESTION',
        type: 'survey',
        notEditable: true,
        translated: true,
        answers: [
          'SURVEY_VERY_STRONG',
          'SURVEY_STRONG',
          'SURVEY_OK',
          'SURVEY_LOW',
          'SURVEY_VERY_LOW',
        ],
      },
      WeeklyMuscleGroup: {
        index: 2,
        name: 'WEEKLY_SORENESS_GROUP',
        id: 'WeeklyMuscleGroup',
        question: 'WEEKLY_SORENESS_GROUP_QUESTION',
        type: 'comment',
        notEditable: true,
        translated: true,
      },
      WeeklyFun: {
        index: 3,
        name: 'WEEKLY_FUN',
        id: 'WeeklyFun',
        question: 'WEEKLY_FUN',
        type: 'survey',
        notEditable: true,
        translated: true,
        answers: [
          'SURVEY_NOT',
          'SURVEY_BIT',
          'SURVEY_OK',
          'SURVEY_MUCH',
          'SURVEY_VERY_MUCH',
        ],
      },
      WeeklyFunFeedback: {
        index: 4,
        name: 'WEEKLY_FUN_REASONS',
        id: 'WeeklyFunFeedback',
        question: 'WEEKLY_FUN_REASONS_QUESTION',
        type: 'comment',
        notEditable: true,
        translated: true,
      },
      WeeklyContent: {
        index: 5,
        name: 'WEEKLY_CONTENT',
        id: 'WeeklyContent',
        question: 'WEEKLY_CONTENT_QUESTION',
        type: 'survey',
        notEditable: true,
        translated: true,
        answers: [
          'SURVEY_NOT',
          'SURVEY_BIT',
          'SURVEY_OK',
          'SURVEY_GOOD',
          'SURVEY_VERY_GOOD',
        ],
      },
      WeeklyContentFeedback: {
        index: 6,
        name: 'WEEKLY_CONTENT_WHY',
        id: 'WeeklyContentFeedback',
        question: 'WEEKLY_CONTENT_WHY_QUESTION',
        type: 'comment',
        notEditable: true,
        translated: true,
      },
      WeeklyQuestion: {
        index: 7,
        name: 'WEEKLY_QUESTIONS',
        id: 'WeeklyQuestion',
        question: 'WEEKLY_QUESTIONS_QUESTION',
        type: 'longText',
        notEditable: true,
        translated: true,
      },
    },
  },
};

// CATEGORY LIBRARY END
// ######################
