import {t} from 'i18n-js';
import {useSelector} from 'react-redux';
import {
  getUserLengthMeasurement,
  getUserVolumeMeasurement,
  getUserWeightMeasurement,
} from './unitSelectors';

import {UnitCategoryType} from './UnitTypes';

export const UnitObjects = (): UnitCategoryType => {
  return {
    kg: {
      categoryId: 'WeightUnit',
      label: t('KG'),
      labelLong: t('KILOGRAM'),
      id: 'kg',
      conversionUnit: 'g',
      conversionRate: 1000,
      pendantId: 'lb',
      pendantRate: 2.20462,
    },
    g: {
      categoryId: 'WeightUnit',
      label: t('G'),
      labelLong: t('GRAM'),
      id: 'g',
      conversionUnit: 'g',
      conversionRate: 1,
      pendantId: 'gUniversal',
      pendantRate: 1,
    },
    mg: {
      categoryId: 'WeightUnit',

      label: t('MG'),
      labelLong: t('MILLIGRAM'),
      id: 'mg',
      conversionUnit: 'g',
      conversionRate: 0.001,
      pendantId: 'mgUniversal',
      pendantRate: 1,
    },
    lb: {
      categoryId: 'WeightUnit',
      label: t('LB'),
      labelLong: t('POUND'),
      id: 'lb',
      conversionUnit: 'g',
      conversionRate: 453.592,
      pendantId: 'kg',
      pendantRate: 0.453592,
      imperial: true,
    },
    oz: {
      categoryId: 'WeightUnit',
      label: t('OZ'),
      labelLong: t('OUNCE'),
      id: 'oz',
      conversionUnit: 'g',
      conversionRate: 28.3495,
      pendantId: 'g',
      pendantRate: 28.3495,
      imperial: true,
    },
    gUniversal: {
      categoryId: 'WeightUnit',
      label: t('G'),
      labelLong: t('GRAM'),
      id: 'gUniversal',
      conversionUnit: 'g',
      conversionRate: 1,
      pendantId: 'gUniversal',
      pendantRate: 1,
      imperial: true,
    },
    mgUniversal: {
      categoryId: 'WeightUnit',
      label: t('MG'),
      labelLong: t('MILLIGRAM'),
      id: 'mgUniversal',
      conversionUnit: 'g',
      conversionRate: 0.001,
      pendantId: 'mgUniversal',
      pendantRate: 1,
      imperial: true,
    },
    h: {
      categoryId: 'DurationUnit',
      label: t('H'),
      labelLong: t('HOUR'),
      id: 'h',
      conversionUnit: 'sec',
      conversionRate: 3600,
    },
    min: {
      categoryId: 'DurationUnit',
      label: t('MIN'),
      labelLong: t('MINUTE'),
      id: 'min',
      conversionUnit: 'sec',
      conversionRate: 60,
    },
    sec: {
      categoryId: 'DurationUnit',

      label: t('SEC'),
      labelLong: t('SECOND'),
      id: 'sec',
      conversionUnit: 'sec',
      conversionRate: 1,
    },
    km: {
      categoryId: 'LengthUnit',
      label: t('KM'),
      labelLong: t('KILOMETER'),
      id: 'km',
      conversionUnit: 'cm',
      conversionRate: 100000,
      pendantId: 'mi',
      pendantRate: 0.621371,
    },
    m: {
      categoryId: 'LengthUnit',
      label: t('M'),
      labelLong: t('METER'),
      id: 'm',
      conversionUnit: 'cm',
      conversionRate: 100,
      pendantId: 'yd',
      pendantRate: 1.09361,
    },
    cm: {
      categoryId: 'LengthUnit',
      label: t('CM'),
      labelLong: t('CENTIMETER'),
      id: 'cm',
      conversionUnit: 'cm',
      conversionRate: 1,
      pendantId: 'in',
      pendantRate: 0.393701,
    },
    mm: {
      categoryId: 'LengthUnit',
      label: t('MM'),
      labelLong: t('MILLIMETER'),
      id: 'mm',
      conversionUnit: 'cm',
      conversionRate: 0.1,
      pendantId: 'in',
      pendantRate: 0.0393701,
    },
    in: {
      categoryId: 'LengthUnit',
      label: t('IN'),
      labelLong: t('INCH'),
      id: 'in',
      conversionUnit: 'cm',
      conversionRate: 2.54,
      pendantId: 'cm',
      pendantRate: 2.54,
      imperial: true,
    },
    ft: {
      categoryId: 'LengthUnit',
      label: t('FT'),
      labelLong: t('FOOT'),
      id: 'ft',
      conversionUnit: 'cm',
      conversionRate: 30.48,
      pendantId: 'cm',
      pendantRate: 30.48,
      imperial: true,
    },
    yd: {
      categoryId: 'LengthUnit',
      label: t('YD'),
      labelLong: t('YARD'),
      id: 'yd',
      conversionUnit: 'cm',
      conversionRate: 91.44,
      pendantId: 'm',
      pendantRate: 0.9144,
      imperial: true,
    },
    mi: {
      categoryId: 'LengthUnit',
      label: t('MI'),
      labelLong: t('MILE'),
      id: 'mi',
      conversionUnit: 'cm',
      conversionRate: 160934,
      pendantId: 'km',
      pendantRate: 1.60934,
      imperial: true,
    },
    l: {
      categoryId: 'VolumeUnit',
      label: t('L'),
      labelLong: t('LITER'),
      id: 'l',
      conversionUnit: 'ml',
      conversionRate: 1000,
      pendantId: 'qt',
      pendantRate: 1.05669,
    },
    ml: {
      categoryId: 'VolumeUnit',
      label: t('ML'),
      labelLong: t('MILLILITER'),
      id: 'ml',
      conversionUnit: 'ml',
      conversionRate: 1,
    },
    floz: {
      categoryId: 'VolumeUnit',
      label: t('FL_OZ'),
      labelLong: t('FLUID_OUNCE'),
      id: 'floz',
      conversionUnit: 'ml',
      conversionRate: 29.5735,
      pendantId: 'ml',
      pendantRate: 29.5735,
      imperial: true,
    },
    pt: {
      categoryId: 'VolumeUnit',
      label: t('PT'),
      labelLong: t('PINT'),
      id: 'pt',
      conversionUnit: 'ml',
      conversionRate: 473.176,
      pendantId: 'ml',
      pendantRate: 473.176,
      imperial: true,
    },
    qt: {
      categoryId: 'VolumeUnit',
      label: t('QT'),
      labelLong: t('QUART'),
      id: 'qt',
      conversionUnit: 'ml',
      conversionRate: 946.353,
      pendantId: 'l',
      pendantRate: 0.946353,
      imperial: true,
    },
    gal: {
      categoryId: 'VolumeUnit',
      label: t('GAL'),
      labelLong: t('GALLON'),
      id: 'gal',
      conversionUnit: 'ml',
      conversionRate: 3785.41,
      pendantId: 'l',
      pendantRate: 3.78541,
      imperial: true,
    },
    bpm: {
      categoryId: 'HeartRateUnit',
      label: t('BPM'),
      labelLong: t('BEATS_PER_MINUTE'),
      id: 'bpm',
      conversionUnit: 'bpm',
      conversionRate: 1,
    },
    kcal: {
      categoryId: 'EnergyUnit',
      label: t('KCAL'),
      labelLong: t('KILOCALORIE'),
      id: 'kcal',
      conversionUnit: 'j',
      conversionRate: 4184,
    },
    kJ: {
      categoryId: 'EnergyUnit',
      label: t('KJ'),
      labelLong: t('KILOJOULE'),
      id: 'kJ',
      conversionUnit: 'j',
      conversionRate: 1000,
    },
    j: {
      categoryId: 'EnergyUnit',
      label: t('J'),
      labelLong: t('JOULE'),
      id: 'j',
      conversionUnit: 'j',
      conversionRate: 1,
    },
    mmHg: {
      categoryId: 'PressureUnit',
      label: t('MMHG'),
      labelLong: t('MMHG_TORR'),
      id: 'mmHg',
      conversionUnit: 'mmHg',
      conversionRate: 1,
    },
    mmolL: {
      categoryId: 'BloodSugarUnit',
      label: t('MMOLL'),
      labelLong: t('MILLIMOL_PER_LITER'),
      id: 'mmolL',
      conversionUnit: 'mmolL',
      conversionRate: 1,
    },
    mgDl: {
      categoryId: 'BloodSugarUnit',
      label: t('MGDL'),
      labelLong: t('MILLIGRAM_PER_DECILITER'),
      id: 'mgDl',
      conversionUnit: 'mmolL',
      conversionRate: 0.0555,
    },
  };
};

export const useGetUnitObject = () => {
  const getUseImperial = useGetUseImperial();
  const data = UnitObjects();
  // ignorePendant is used to get the unit object without handling imperial <=> metric
  return (unitId: string, ignorePendant?: boolean) => {
    const imperial = getUseImperial(unitId);
    const unitObject = data[unitId];
    if (
      Boolean(unitObject?.imperial) === imperial ||
      ignorePendant ||
      !unitObject?.pendantId
    ) {
      return unitObject;
    } else {
      return data[unitObject?.pendantId];
    }
  };
};

export const useGetUseImperial = () => {
  const userWeightUnit = useSelector(getUserWeightMeasurement);
  const userLengthUnit = useSelector(getUserLengthMeasurement);
  const userVolumeUnit = useSelector(getUserVolumeMeasurement);
  return (unitId: string) => {
    const unitObject = UnitObjects()[unitId];
    switch (unitObject?.categoryId) {
      case 'WeightUnit':
        return userWeightUnit === 'lbs';
      case 'LengthUnit':
        return userLengthUnit === 'imperial';
      case 'VolumeUnit':
        return userVolumeUnit === 'imperial';
      default:
        return false;
    }
  };
};

export const useGetUnitValueFrontend = () => {
  const getUnitObject = useGetUnitObject();
  const getUseImperial = useGetUseImperial();
  return (
    unitId?: string,
    value?: number | string,
    dezimal: 1 | 10 | 100 | 1000 = 100,
  ) => {
    let newValue = value;
    if (newValue === '' || newValue === undefined) {
      return value;
    }

    if (typeof newValue === 'string') {
      newValue = newValue.replace(',', '.');
      if (isNaN(newValue as any)) {
        return value;
      }
      newValue = parseFloat(newValue);
      if (isNaN(newValue as any)) {
        return value;
      }
    }
    if (unitId !== undefined) {
      const useImperial = getUseImperial(unitId);
      const unitObject = getUnitObject(unitId);
      newValue = newValue * (1 / unitObject.conversionRate);

      if (
        useImperial !== Boolean(unitObject.imperial) &&
        unitObject.pendantRate
      ) {
        newValue = newValue * unitObject.pendantRate;
      }
    }
    return Math.round(newValue * dezimal) / dezimal;
  };
};

export const useGetUnitValueBackend = () => {
  const getUnitObject = useGetUnitObject();

  return (value: number | string, unitId?: string) => {
    let newValue = value;
    if (newValue === '' || newValue === undefined) {
      return value;
    }
    if (typeof newValue === 'string') {
      newValue = newValue.replace(',', '.');
      if (isNaN(newValue as any)) {
        return value;
      }
      newValue = parseFloat(newValue);
      if (isNaN(newValue as any)) {
        return value;
      }
    }

    if (unitId !== undefined) {
      const unitObject = getUnitObject(unitId);
      newValue = newValue * unitObject.conversionRate;
    }

    return newValue;
  };
};

export const useAllUnits = () => {
  return UnitObjects();
};

export const useUserUnits = (): UnitCategoryType => {
  const userWeightUnit = useSelector(getUserWeightMeasurement);
  const userLengthUnit = useSelector(getUserLengthMeasurement);
  const userVolumeUnit = useSelector(getUserVolumeMeasurement);
  const imperialWeight = userWeightUnit === 'lbs';
  const imperialLength = userLengthUnit === 'imperial';
  const imperialVolume = userVolumeUnit === 'imperial';
  const returnData: UnitCategoryType = {};
  const unitObjects = UnitObjects();
  for (const key in unitObjects) {
    const unitObject = unitObjects[key];
    const unitImperial = Boolean(unitObject.imperial);
    switch (unitObject.categoryId) {
      case 'VolumeUnit':
        if (unitImperial === imperialVolume) {
          returnData[key] = unitObject;
        }
        break;
      case 'LengthUnit':
        if (unitImperial === imperialLength) {
          returnData[key] = unitObject;
        }
        break;
      case 'WeightUnit':
        if (unitImperial === imperialWeight) {
          returnData[key] = unitObject;
        }
        break;
      default:
        returnData[key] = unitObject;
        break;
    }
  }
  return returnData;
};
