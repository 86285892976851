import {useSelector} from 'react-redux';
import {getUserLengthMeasurement} from './unitSelectors';

export const useCalculateLengthBackend = () => {
  const measurement = useSelector(getUserLengthMeasurement);
  let useImperial = false;
  if (measurement === 'imperial') {
    useImperial = true;
  }
  return (value: number | string) => {
    let newValue = value;

    if (typeof newValue === 'string') {
      newValue = newValue.replace(',', '.');
      if (newValue === '' || isNaN(newValue as any)) {
        return value;
      }
      newValue = parseFloat(newValue);
    }

    if (useImperial) {
      return newValue * 2.54;
    }
    return newValue;
  };
};
