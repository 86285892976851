import {CloseRounded} from '@mui/icons-material';
import {Button} from '@mui/material';
import {t} from 'i18n-js';
import {CSSProperties, useRef, useState} from 'react';
import ReactPlayer from 'react-player';
import {primary_green, sidebar_color_dark, white} from '../styles/colors';

export const AthleteVideoScreen = () => {
  const playerRef = useRef(null);
  const canvasRef = useRef(null);
  const [isPaused, setIsPaused] = useState(false);

  const captureFrame = () => {
    const player = playerRef.current.getInternalPlayer();
    const canvas = canvasRef.current;
    if (player && canvas) {
      const context = canvas.getContext('2d');
      canvas.width = player.videoWidth;
      canvas.height = player.videoHeight;

      context.drawImage(player, 0, 0, canvas.width, canvas.height);
    }
  };

  const handlePause = () => {
    setIsPaused(true);
    captureFrame();
  };

  const handlePlay = () => {
    setIsPaused(false);
  };

  const handleClose = () => {
    window.close();
  };
  const url = window['urlString'];
  const exerciseName = window['exerciseName'];

  return (
    <div style={styles.container}>
      <div style={styles.headerContainer}>
        <div style={styles.headerText}>
          {t('EXERCISE_VIDEO_PLAYER_HEADLINE')}
        </div>
        <Button onClick={handleClose}>
          <CloseRounded style={styles.closeButton} />
        </Button>
      </div>
      <div style={styles.title}>{exerciseName}</div>
      <div style={styles.videoContainer}>
        <canvas
          ref={canvasRef}
          style={{...styles.canvas, ...{display: isPaused ? 'block' : 'none'}}}
        />
        <ReactPlayer
          ref={playerRef}
          url={url}
          controls
          playing={!isPaused}
          onPause={handlePause}
          onPlay={handlePlay}
          width="100%"
          height="100%"
          style={styles.video}
        />
      </div>
    </div>
  );
};

type Styles = {
  modal: CSSProperties;
  container: CSSProperties;
  headerContainer: CSSProperties;
  headerText: CSSProperties;
  closeButton: CSSProperties;
  video: CSSProperties;
  button: CSSProperties;
  title: CSSProperties;
  videoContainer: CSSProperties;
  canvas: CSSProperties;
};

const styles: Styles = {
  button: {
    backgroundColor: primary_green,
    color: white,
  },
  modal: {
    display: 'flex',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    paddingTop: 50,
    paddingBottom: 50,
    background: sidebar_color_dark,
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingLeft: 80,
    paddingRight: 40,
    marginRight: 30,
    marginBottom: 25,
  },
  headerText: {
    fontSize: 34,
    color: white,
  },
  closeButton: {
    color: white,
  },
  video: {
    position: 'absolute',
    top: '0',
    left: '0',
  },
  videoContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  title: {
    fontSize: 34,
    color: white,
    textAlign: 'center',
    width: '100%',
  },
  canvas: {
    position: 'absolute',
    top: '0',
    alignSelf: 'center',
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
};
