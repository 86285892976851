import {getUser} from '../../logic/firestore';
import {RootState} from '../../store/store';

export const getUserWeightMeasurement = (state: RootState) => {
  const user = getUser(state);
  if (user?.measurement !== undefined) {
    return user.measurement;
  }
  return 'kg';
};
export const getUserLengthMeasurement = (state: RootState) => {
  return getUser(state)?.lengthMeasurement ?? 'metric';
};
export const getUserVolumeMeasurement = (state: RootState) => {
  return getUser(state)?.volumeMeasurement ?? 'metric';
};
