export const config = {
  development: {
    apiKey: 'AIzaSyC4OQQ9hqfvKaxG5kD8nqPqEo_KTiTYMoQ',
    authDomain: 'traindoo-dev.firebaseapp.com',
    projectId: 'traindoo-dev',
    storageBucket: 'traindoo-dev.appspot.com',
    messagingSenderId: '894878067474',
    appId: '1:894878067474:web:46e7900b84e15a843721cb',
    measurementId: 'G-HF8V8QYB5B',
  },
  staging: {
    apiKey: 'AIzaSyC4OQQ9hqfvKaxG5kD8nqPqEo_KTiTYMoQ',
    authDomain: 'traindoo-dev.firebaseapp.com',
    projectId: 'traindoo-dev',
    storageBucket: 'traindoo-dev.appspot.com',
    messagingSenderId: '894878067474',
    appId: '1:894878067474:web:46e7900b84e15a843721cb',
    measurementId: 'G-HF8V8QYB5B',
  },
  production: {
    apiKey: 'AIzaSyCzyRT1ubAdLhOYCN70DdmeEMwbNGDDi9Q',
    authDomain: 'traindoo-io.firebaseapp.com',
    projectId: 'traindoo-io',
    storageBucket: 'traindoo-io.appspot.com',
    messagingSenderId: '795251231636',
    appId: '1:795251231636:web:9a44c016aacb4805245d81',
    measurementId: 'G-JW76K43VVY',
  },
};
