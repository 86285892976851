import moment from 'moment';
import {ExtendedFirestoreInstance} from 'react-redux-firebase';
import {chatMessageQuery} from '../selectors/chatQueries';
import {ChatTypes, Message} from '../types/ChatTypes';

/* MESSAGES ARRAY FCNS */

const isMsgAlreadyInMsgs = (msgs: Message[], msg: Message) => {
  return msgs.find((element) => element.messageId === msg.messageId);
};

const isMsgEmpty = (msg: Message) => {
  return msg.contentText.trim() === '';
};

export const canMsgBeAddedToMsgsArray = (
  msgs: Message[],
  msg: Message | undefined,
) => {
  // make sure msg is not undefined
  return msg && !isMsgEmpty(msg) && !isMsgAlreadyInMsgs(msgs, msg);
};

/* when providing compareFcn to sort(), compareFcn needs to return
either <0, >0 or 0, which is exactly what moment.diff() does. */
export const compareFcnMessageDate = (a: Message, b: Message) => {
  return moment(b.sentAt).diff(a.sentAt);
};

/* FIRESTORE FCNS */

export const setMsgsToSeenInFirestore = (
  firestore: ExtendedFirestoreInstance,
  messageDocs: any,
  userId: string,
) => {
  // @ts-ignore - batch is not defined in the type definition
  const seenUpdateBatch = firestore.batch();
  // docSnapshot is actually of type firebase.firestore.QueryDocumentSnapshot
  messageDocs?.forEach((docSnapshot: any) => {
    const msg: Message = docSnapshot.data();
    if (msg.unseen.includes(userId)) {
      seenUpdateBatch.update(docSnapshot.ref, {
        unseen: firestore.FieldValue.arrayRemove(userId),
      });
    }
  });
  seenUpdateBatch.commit();
};

export const getChatQueryUnseenMsgs = (
  firestore: ExtendedFirestoreInstance,
  chatId: string,
  userId: string,
): any => {
  return getChatQuery(firestore, chatId).where(
    'unseen',
    'array-contains',
    userId,
  );
};

export const getChatQuery = (
  firestore: ExtendedFirestoreInstance,
  chatId: string,
): any => {
  return getMessagesCollectionRef(firestore)
    .where('chatId', '==', chatId)
    .orderBy('sentAt', 'desc');
};

export const getMessagesCollectionRef = (
  firestore: ExtendedFirestoreInstance,
) => {
  return (
    firestore
      //@ts-ignore firebase object wrongly typed
      .collection('messages')
  );
};

export const uploadMsgToFirestore = (
  text: string,
  sentBy: string,
  trainerId: string,
  participantIds: string[],
  messageId: string,
  chatId: string | undefined,
  chatType: ChatTypes,
  firestore: ExtendedFirestoreInstance,
) => {
  const messageToSend: Message = {
    trainerId: trainerId,
    ...(chatId && {chatId: chatId}),
    messageId: messageId,
    contentText: text,
    // contentAsset: {url: '', assetId: '', dataName: '', dataType: ''},
    sentAt: moment().utc().toISOString(),
    sentBy: sentBy,
    participantIds: participantIds,
    contentType: 'textOnly',
    notReceived: participantIds.filter((id) => id !== sentBy),
    unseen: participantIds.filter((id) => id !== sentBy),
    chatType: chatType,
  };
  firestore.set(chatMessageQuery(messageId), messageToSend);
  return messageToSend;
};
